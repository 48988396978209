import React from 'react'
import Reveal from 'react-reveal/Reveal'
import HighlightedTextSection from '../sections/HighlightedTextSection'
import styles from '../styles/components/our-services.module.scss'
import '../styles/animate.css'

const OurServices = props => {
  const subsectionsJSX = props.subsections.map(subsection => {
    return (
      <Reveal effect="fadeInUp">
        <div className={styles.subsection}>
          <div className={styles.dividingLine} />
          <div className={styles.subtitle}>{subsection.header}</div>
          <div className={styles.bodyText}>{subsection.body.body}</div>
        </div>
      </Reveal>
    )
  })
  return (
    <div className={styles.container}>
      {/* <div className={styles.verticalTitleContainer}>
        <Reveal effect="ourServicesFadeInLeft" fraction={0.75} duration={1600}>
          <div className={styles.sectionTitle}>How We Can Help</div>
        </Reveal>
      </div>
      <div className={styles.sectionHeader}>{props.sectionHeader}</div> */}
      <HighlightedTextSection
        title={props.sectionHeader}
        label="Ways we can help"
        spacing="small"
      />
      <div className={styles.subsectionsContainer}>{subsectionsJSX}</div>
    </div>
  )
}

export default OurServices
