import React from 'react'
import Hero from './hero'
import FullImage from './fullImage'
import ThreePoints from './threePoints'
import OurServices from './ourServices'
import WhatTheFinch from './whatTheFinch'
import FeaturedProjectsCarousel from './featuredProjectsCarousel'
import ImageGrid from './imageGrid'
import CompanySnapshot from './companySnapshot'
import ImageGallery from './imageGallery'
import Quote from './quote'
import ContentPanel from './contentPanel'

const Section = props => {
  // console.log('In section.js: typename is ' + props.__typename)
  switch (props.__typename) {
    case 'ContentfulHero':
      return <Hero {...props} />
    case 'ContentfulFullImage':
      return <FullImage {...props} />
    case 'ContentfulThreePoints':
      return <ThreePoints {...props} />
    case 'ContentfulOurServices':
      return <OurServices {...props} />
    case 'ContentfulWhatTheFinch':
      return <WhatTheFinch {...props} />
    case 'ContentfulFeaturedProjectsCarousel':
      return <FeaturedProjectsCarousel {...props} />
    case 'ContentfulImageGrid':
      return <ImageGrid {...props} />
    case 'ContentfulCompanySnapshot':
      return <CompanySnapshot {...props} />
    case 'ContentfulImageGallery':
      return <ImageGallery {...props} />
    case 'ContentfulContentPanel':
      return <ContentPanel {...props} />
    case 'ContentfulQuote':
      return <Quote {...props} />
    default:
      return <span>Section not recognized...</span>
  }
}

export default Section
