import React from 'react'
import styles from '../styles/components/logo-grid.module.scss'
import Img from 'gatsby-image'

import anvylThumbnail from '../images/anvyl-background.jpg'

const ImageGrid = props => {
  const { images } = props
  //console.log('networking section title is ' + props.sectionTitle)
  const imageArray = images.map((image, i) => {
    //console.log('this image is ' + image)
    return (
      <div className={styles.imageContainer} key={i}>
        <Img fluid={image.fluid} alt="" />
      </div>
    )
  })
  return (
    <div className={styles.sectionContainer}>
      <div className={styles.header}>{props.sectionTitle}</div>
      <div className={styles.gridContainer}>{imageArray}</div>
    </div>
  )
}

export default ImageGrid
