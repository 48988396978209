import React from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import styles from '../styles/components/three-points.module.scss'
import Reveal from 'react-reveal/Reveal'
import number1 from '../images/01.png'
import number2 from '../images/02.png'
import number3 from '../images/03.png'
import rightArrowWhite from '../images/right-arrow-white.png'
import '../styles/animate.css'

const ThreePoints = props => {
  const pointGroups = props.sections.map((section, i) => {
    let imageSrc
    switch (i) {
      case 0:
        imageSrc = number1
        break
      case 1:
        imageSrc = number2
        break
      case 2:
        imageSrc = number3
        break
      default:
        imageSrc = null
        break
    }
    return (
      <div className={styles.pointGroup}>
        <div className={styles.imageGroup}>
          <Reveal effect="fadeInLeft" fraction={0.9}>
            <img className={styles.number} src={imageSrc} alt={i + 1} />
          </Reveal>
          <Reveal effect="fadeInLeftLonger" fraction={0.9} duration={1500}>
            <div className={styles.icon}>
              <Img loading="eager" fixed={section.icon.fixed} alt="" />
            </div>
          </Reveal>
        </div>
        <Reveal effect="fadeInUp" fraction={0.5}>
          <div className={styles.textBox}>
            <div className={styles.subheader}>{section.header}</div>
            <div
              className={styles.text}
              dangerouslySetInnerHTML={{
                __html: section.text.childMarkdownRemark.html,
              }}
            />
          </div>
        </Reveal>
      </div>
    )
  })
  return (
    <div className={styles.backgroundBox}>
      {pointGroups}
      <Link to="/contact-form/">
        <div className={styles.bottomImageGroup}>
          <div className={styles.imageContainer}>
            <Img
              loading="eager"
              fluid={props.bottomImage.fluid}
              className={styles.bottomImage}
              alt=""
            />
          </div>
          <div className={styles.imageOverlay} />
          <Reveal effect="centeredFadeInLeft">
            <div className={styles.callToAction}>
              <p>Interested in working together?</p>
              <Link
                to="/contact-form"
                style={{
                  color: 'white',
                  fontWeight: 'bold',
                  textDecoration: 'none',
                  fontSize: '16px',
                  float: 'right',
                }}
              >
                Reach out
                <img className={styles.arrow} src={rightArrowWhite} alt="" />
              </Link>
            </div>
          </Reveal>
        </div>
      </Link>
    </div>
  )
}

export default ThreePoints
