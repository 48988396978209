import React from 'react'
import Img from 'gatsby-image'
import Reveal from 'react-reveal/Reveal'
import '../styles/animate.css'
import styles from '../styles/components/content-panel.module.scss'
import classNames from 'classnames/bind'

let cx = classNames.bind(styles)

const ContentPanel = class extends React.Component {
  render() {
    // console.log(
    //   'background color of content panel ' + this.props.backgroundColor
    // )
    let containerClass = cx('flexContainer', {
      isReversed: this.props.isReversed === 'true',
      isVertical: this.props.isVertical === 'true',
    })
    let backgroundColor = cx({
      lightGray: true ? this.props.backgroundColor === 'lightgray' : false,
    })
    return (
      <div className={backgroundColor}>
        <div className={containerClass}>
          <Reveal effect="fadeIn" duration={1750}>
            <div className={styles.imageBlock}>
              {this.props.image && (
                //<img src={this.props.image.file.url} alt="image" />
                <Img loading="eager" fluid={this.props.image.fluid} alt="" />
              )}
            </div>
          </Reveal>
          <Reveal effect="fadeInUpShorter" fraction={0.5}>
            <div className={styles.textBlock}>
              {this.props.text.header && (
                <div className={styles.header}>{this.props.text.header}</div>
              )}
              {this.props.text.body.body && (
                <div className={styles.body}>{this.props.text.body.body}</div>
              )}
            </div>
          </Reveal>
        </div>
      </div>
    )
  }
}

export default ContentPanel
