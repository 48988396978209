import React from 'react'
import Hammer from 'react-hammerjs'
import styles from '../styles/components/image-gallery.module.scss'
import FullImage from './fullImage'
import leftArrow from '../images/left-arrow.png'
import rightArrow from '../images/right-arrow.png'
import Sliders from './sliders'

const ImageGallery = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = { currentSlide: 0 }
    this.incrementSlide = this.incrementSlide.bind(this)
    this.selectSlide = this.selectSlide.bind(this)
    this.handleSwipe = this.handleSwipe.bind(this)
  }

  componentDidMount() {
    this.interval = setInterval(() => this.incrementSlide(true), 5000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  handleSwipe(e) {
    switch (e.offsetDirection) {
      case 4: //Left
        this.incrementSlide(false)
        break
      case 2: //Right
        this.incrementSlide(true)
        break
      default:
        break
    }
  }

  incrementSlide(isNext) {
    const slideCount = this.props.images.length
    let currentSlide = this.state.currentSlide + (isNext ? 1 : -1)
    if (currentSlide < 0) {
      currentSlide = slideCount - 1
    } else if (currentSlide >= slideCount) {
      currentSlide = 0
    }
    this.setState({ currentSlide })
  }

  selectSlide(index) {
    clearInterval(this.interval)
    this.setState({ currentSlide: index })
  }

  render() {
    const { images } = this.props
    const { currentSlide } = this.state

    return (
      <div>
        {images &&
          images.map((image, i) => {
            if (i === currentSlide) {
              return (
                <Hammer onSwipe={this.handleSwipe}>
                  <div className={styles.section}>
                    <div className={styles.arrowContainer}>
                      <div
                        className={styles.leftArrow}
                        onClick={i => this.incrementSlide(false)}
                      >
                        <img src={leftArrow} alt="" />
                      </div>
                      <div
                        className={styles.rightArrow}
                        onClick={i => this.incrementSlide(true)}
                      >
                        <img src={rightArrow} alt="" />
                      </div>
                    </div>
                    <div>
                      <FullImage animate={true} imageURL={image} />
                    </div>
                    <div className={styles.slidersContainer}>
                      <Sliders
                        count={images.length}
                        index={currentSlide}
                        onChange={i => this.selectSlide(i)}
                      />
                    </div>
                  </div>
                </Hammer>
              )
            }
          })}
      </div>
    )
  }
}

export default ImageGallery
