import React from 'react'
import Img from 'gatsby-image'
import styles from '../styles/components/full-image.module.scss'
import classNames from 'classnames/bind'

let cx = classNames.bind(styles)

const FullImage = props => {
  // Image Gallery passes in a specific url but
  // Section case switch sends in queried image, need to access url\
  //console.log('imageURL start of fullimage ' + props.imageURL)
  let imageURL = props.imageURL
  if (imageURL === undefined) {
    imageURL = props.image
    //console.log('image url === null ' + props.file.url)
  }
  let imageClass = cx('image', { animate: props.animate })
  return (
    <Img loading="eager" className={imageClass} fluid={imageURL.fluid} alt="" />
  )
}

export default FullImage
