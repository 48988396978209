import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/SEO'
import Hero from '../components/hero'
import CompanySnapshot from '../components/companySnapshot'
import CaseStudyLinks from '../components/CaseStudyLinks'

import Section from '../components/section'

import { graphql } from 'gatsby'

const CaseStudyTemplate = ({ data, pageContext }) => {
  const {
    title,
    heroImage,
    mobileHeroImage,
    companySnapshot,
    pageSections,
  } = data.contentfulCaseStudy

  const caseStudyNode = data.contentfulCaseStudy
  const previous = pageContext.prev
  const next = pageContext.next

  // check window size to determine which hero image to use
  let screenSizedHeroImage = heroImage
  if (typeof window !== `undefined`) {
    if (window.innerWidth <= 768 && mobileHeroImage !== null) {
      screenSizedHeroImage = mobileHeroImage
    }
  }

  return (
    <Layout>
      <SEO postNode={caseStudyNode} caseStudySEO />
      <Hero
        backgroundColor="none"
        hasBackgroundImage={true}
        imageURL={screenSizedHeroImage.file.url}
        imageFluid={screenSizedHeroImage}
        hasText={false}
      />
      {companySnapshot && (
        <CompanySnapshot companyName={title} {...companySnapshot} />
      )}
      {pageSections &&
        pageSections.map((section, i) => {
          return <Section key={i} {...section} />
        })}
      <CaseStudyLinks previous={previous} next={next} />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulCaseStudy(slug: { eq: $slug }) {
      title
      slug

      heroImage {
        file {
          url
        }
        fluid(maxWidth: 1280, quality: 100) {
          ...GatsbyContentfulFluid_withWebp
        }
        ogimg: resize(width: 1800) {
          src
          width
          height
        }
      }

      mobileHeroImage {
        file {
          url
        }
        fluid(maxWidth: 512, quality: 100) {
          ...GatsbyContentfulFluid_withWebp
        }
      }

      companySnapshot {
        ... on ContentfulCompanySnapshot {
          __typename
          id
          text {
            ... on ContentfulHeaderTextBody {
              header
              body {
                body
              }
            }
          }
          companySiteUrl
        }
        rightColumnLists {
          ... on ContentfulHeaderTextList {
            header
            textList
          }
        }
      }

      pageSections {
        ... on ContentfulContentPanel {
          contentfulid
          __typename
          text {
            ... on ContentfulHeaderTextBody {
              __typename
              header
              body {
                body
              }
            }
          }
          image {
            file {
              url
            }
            fluid(maxWidth: 670, quality: 100) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          isReversed
          isVertical
          backgroundColor
        }
        ... on ContentfulFullImage {
          __typename
          identifier
          image {
            fluid(maxWidth: 1280, quality: 100) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
        ... on ContentfulImageGallery {
          __typename
          images {
            fluid(maxWidth: 1280, quality: 100) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
        ... on ContentfulQuote {
          __typename
          id
          quoteAndSource {
            header
            body {
              body
            }
          }
          recentNewsList {
            header
            textList
          }
          newsArticleTitlesAndLinks {
            header
            link
          }
        }
      }
    }
  }
`
export default CaseStudyTemplate
