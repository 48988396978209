import React from 'react'
import styles from '../styles/components/quote.module.scss'

const Quote = props => {
  return (
    <div className={styles.flexContainer}>
      {props.quoteAndSource && (
        <div className={styles.quoteBlock}>
          <div className={styles.quote}>{props.quoteAndSource.body.body}</div>
          <div className={styles.source}>{props.quoteAndSource.header}</div>
        </div>
      )}
      {/* remove false when real articles have been added */}
      {props.recentNewsList && (
        <div className={styles.rightColumn}>
          <div className={styles.recentNews}>{props.recentNewsList.header}</div>
          <div className={styles.textList}>
            {props.newsArticleTitlesAndLinks.map((item, i) => {
              return (
                <div className={styles.listItem} key={i}>
                  <a href={item.link} target="_blank">
                    {item.header}
                  </a>
                </div>
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}

export default Quote
