import React from 'react'
import { Link } from 'gatsby'
import Reveal from 'react-reveal/Reveal'
import styles from '../styles/components/company-snapshot.module.scss'
import '../styles/animate.css'

import rightArrowDark from '../images/right-arrow-dark.png'
import rightArrowBlue from '../images/right-arrow-blue.png'

// use svgs to remove state from this class and make function
const CompanySnapshot = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = { arrowURL: rightArrowDark }
  }

  mouseEnterLink = () => {
    this.setState({
      arrowURL: rightArrowBlue,
    })
  }

  mouseLeaveLink = () => {
    this.setState({
      arrowURL: rightArrowDark,
    })
  }

  render() {
    let subsectionsJSX
    if (this.props.rightColumnLists !== null) {
      subsectionsJSX = this.props.rightColumnLists.map((list, i) => {
        //console.log('hello')
        return (
          <div className={styles.listContainer} key={i}>
            {list.header && (
              <Reveal effect="fadeInUpShorter">
                <div className={styles.header}>{list.header}</div>
              </Reveal>
            )}
            {list?.textList?.map((item, i) => {
              return (
                <Reveal effect="fadeInUpShorter" duration={1500} key={i}>
                  <div className={styles.listItem}>{item}</div>
                </Reveal>
              )
            })}
          </div>
        )
      })
    } else {
      subsectionsJSX = ''
    }
    return (
      <div className={styles.section}>
        <Reveal effect="fadeInUpShorter">
          <div className={styles.companyName}>{this.props.companyName}</div>
        </Reveal>
        <div className={styles.container}>
          <div className={styles.leftColumn}>
            <Reveal effect="fadeInUpShorter" duration={1500}>
              <div className={styles.header}>{this.props.text.header}</div>
            </Reveal>
            <Reveal effect="fadeInUpShorter" duration={2000}>
              <div className={styles.bodyText}>{this.props.text.body.body}</div>
              <a
                href={this.props.companySiteUrl}
                target="_blank"
                className={styles.companySiteLink}
                onMouseEnter={this.mouseEnterLink.bind(this)}
                onMouseLeave={this.mouseLeaveLink.bind(this)}
              >
                Visit the {this.props.companyName} website
                <img
                  className={styles.arrow}
                  src={this.state.arrowURL}
                  alt=""
                />
              </a>
            </Reveal>
          </div>
          <div className={styles.rightColumn}>{subsectionsJSX}</div>
        </div>
      </div>
    )
  }
}

export default CompanySnapshot
