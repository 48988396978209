import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import circleText from '../images/spinning-text.png'
import finchHead from '../images/15-Finches-Icon.png'

import styles from '../styles/components/spinning-finch.module.scss'

const SpinningFinch = () => {
  const [rotation, setRotation] = useState(360)
  const [hover, setHover] = useState(false)
  useEffect(() => {
    let timeout = setTimeout(() => {
      setRotation(
        rotation <= 0
          ? 360 - rotation - (hover ? 1 : 0.2)
          : rotation - (hover ? 1 : 0.2)
      )
    }, 14)
    return () => {
      clearTimeout(timeout)
    }
  }, [rotation, hover])

  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={styles.circleIconGroup}
    >
      <Link to="/">
        <div className={styles.circleFinchIconWrapper}>
          <img
            style={{ transform: `rotate(${rotation}deg)` }}
            src={circleText}
            className={styles.circleFinchIcon}
          />
        </div>
        <img src={finchHead} className={styles.finchHead} />
      </Link>
    </div>
  )
}

export default SpinningFinch
